.new-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 5%;
    height: 80vh;
    position: fixed;
    width: 100vw;
}
.btn1{
    height: 30%;
    width: 20%;
    background-color: #850E35;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 10px #888888;
    cursor:pointer;
}
.btn2{
    height: 30%;
    width: 20%;
    background-color: #422b01;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 10px #888888;
    cursor:pointer;
}
.contact{
    height: 10%;
    width: 100%;
    background-color: #2B580C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor:pointer;
}
.btn1:hover{
    box-shadow: 5px 10px #888888;
    cursor:pointer;
}
.btn2:hover{
    box-shadow: 5px 10px #888888;
    cursor:pointer;
}
.free-text-new{
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10%;
  }
  @media only screen and (min-width:600px) and (max-width: 800px) {
    .btn1{
        height: 30%;
        width: 40%;
        background-color: #850E35;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 10px #888888;
        cursor:pointer;
    }
    .btn2{
        height: 30%;
        width: 40%;
        background-color: #422b01;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 10px #888888;
        cursor:pointer;
    }
    .contact{
        height: 10%;
        width: 100%;
        background-color: #2B580C;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        text-align: center;
    }
  }
  @media only screen and (max-width: 600px) {
    .btn1{
        height: 30%;
        width: 80%;
        background-color: #850E35;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        box-shadow: 5px 10px #888888;
    }
    .btn2{
        height: 30%;
        width: 80%;
        background-color: #422b01;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        box-shadow: 5px 10px #888888;
    }
    .contact{
        height: 10%;
        width: 100%;
        background-color: #2B580C;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        text-align: center;
    }
  }

  @media only screen and (min-width:800px) and (max-width: 950px)  {
    .btn1{
        height: 30%;
        width: 50%;
        background-color: #850E35;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 10px #888888;
        cursor:pointer;
    }
    .btn2{
        height: 30%;
        width: 50%;
        background-color: #422b01;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        box-shadow: 5px 10px #888888;
    }
    .contact{
        height: 10%;
        width: 100%;
        background-color: #2B580C;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        text-align: center;
    }
  }