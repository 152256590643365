@import url("https://fonts.googleapis.com/css?family=Barlow");
* {
  margin: 0;
  font-family: "Barlow";
   background-color: black; 
   color: white; 
}
.app-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 3vh;
}
.app-body {
  position: relative;
  top: 16vh;
}
.free-text{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
  bottom: 10%;
}
#whatsapp {
  position: fixed;
  bottom: 1%;
  right: 1%;
  z-index: 100;
  height: 10%;
}
@media only screen and (max-width: 600px) {
  #whatsapp {
    position: fixed;
    bottom: 1%;
    right: 3%;
    z-index: 100;
    height: 6vh;
  }
  .free-text{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aqua;
    margin-top: 30%;
    bottom: 10%;
  }
}
