.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    z-index: 50;
    height: 16vh;
  }
  .header_center {
    display: flex;
  }
  .header_left {
    display: flex;
  }
  .header_right {
    display: flex;
    margin-right: 2%;
  }
  .header_icon {
    object-fit: contain;
    height: 14vh;
    margin-top: 0px;
  }